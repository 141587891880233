<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
		<div class="w-100 h-auto flex flex-column">
			<el-form ref="form" :model="form" :rules="rules" label-width="150px" size="small">
				<el-form-item label="学期" prop="s_id">
				  <el-select v-model="form.s_id" placeholder="请选择学期" style="width: 100%;">
				    <el-option :value="item.id" :label="item.year+' '+item.tag" v-for="(item,index) in semester" :key="index"/>
				  </el-select>
				</el-form-item>
				<el-form-item label="奖励名称" prop="title">
				  <el-input v-model="form.title" placeholder="请输入奖励名称" type="text" clearable />
				</el-form-item>
				<el-form-item label="学科" prop="xueke">
				  <el-select v-model="form.xueke" placeholder="请选择学科" style="width: 100%;">
				    <el-option :value="item.id" :label="item.name" v-for="(item,index) in xuekes" :key="index"/>
				  </el-select>
				</el-form-item>
				<el-form-item label="申请项目">
				    <div class="w-100 flex flex-column">
				    	<el-checkbox v-model="form.project_1">辅导学生获省级一等奖</el-checkbox>
				    	<el-checkbox v-model="form.project_2">辅导学生进入省队</el-checkbox>
				    	<el-checkbox v-model="form.project_3">辅导学生获国家银牌</el-checkbox>
				    	<el-checkbox v-model="form.project_4">辅导学生获国家金牌</el-checkbox>
				    </div>
				</el-form-item>
				<el-form-item label="获省级一等奖学生" v-if="form.project_1">
				    <div class="w-100 flex flex-column">
				    	<div class="flex-1 flex align-center mb-1" v-for="(item,index) in sjydj" :key="index">
							<div class="flex-1 h-100 flex align-center">
								<el-select v-model="item.nianji" placeholder="请选择年级" style="width: 100%;">
								  <el-option :value="item.name" :label="item.name" v-for="(item,index) in nianji" :key="item.id"/>
								</el-select>
							</div>
						    <div class="flex-1 h-100 flex align-center mx-2">
						    	<el-input v-model="item.name" type="text" placeholder="请输入学生姓名" size="small" clearable class="flex-1"></el-input>
						    </div>
							<div class="flex-1 h-100 flex align-center mr-2">
								<el-input v-model="item.jl_name" type="text" placeholder="请输入获奖名称" size="small" clearable class="flex-1"></el-input>
							</div>
						    <el-tooltip class="item" effect="dark" content="删除" placement="top">
								<el-button type="danger" size="mini" @click="delSjSpec(index)">
									<i class="el-icon-minus"></i>
								</el-button>
							</el-tooltip>
				    	</div>
						<div class="w-100 flex align-center mt-1">
							<el-button type="danger" size="mini" @click="addSjParams">
								<i class="el-icon-plus"></i>添加
							</el-button>
						</div>
				    </div>
				</el-form-item>
				<el-form-item label="辅导学生进入省队" v-if="form.project_2">
				    <div class="w-100 flex flex-column">
				    	<div class="flex-1 flex align-center mb-1" v-for="(item,index) in fdjsd" :key="index">
							<div class="flex-1 h-100 flex align-center">
								<el-select v-model="item.nianji" placeholder="请选择年级" style="width: 100%;">
								  <el-option :value="item.name" :label="item.name" v-for="(item,index) in nianji" :key="item.id"/>
								</el-select>
							</div>
							<div class="flex-1 h-100 flex align-center mx-2">
								<el-input v-model="item.name" type="text" placeholder="请输入学生姓名" size="small" clearable class="flex-1"></el-input>
							</div>
							<div class="flex-1 h-100 flex align-center mr-2">
								<el-input v-model="item.jl_name" type="text" placeholder="请输入获奖名称" size="small" clearable class="flex-1"></el-input>
							</div>
						    <el-tooltip class="item" effect="dark" content="删除" placement="top">
								<el-button type="danger" size="mini" @click="delSdSpec(index)">
									<i class="el-icon-minus"></i>
								</el-button>
							</el-tooltip>
				    	</div>
						<div class="w-100 flex align-center mt-1">
							<el-button type="danger" size="mini" @click="addSdParams">
								<i class="el-icon-plus"></i>添加
							</el-button>
						</div>
				    </div>
				</el-form-item>
				<el-form-item label="辅导学生获国家银牌" v-if="form.project_3">
				    <div class="w-100 flex flex-column">
				    	<div class="flex-1 flex align-center mb-1" v-for="(item,index) in gjyp" :key="index">
							<div class="flex-1 h-100 flex align-center">
								<el-select v-model="item.nianji" placeholder="请选择年级" style="width: 100%;">
								  <el-option :value="item.name" :label="item.name" v-for="(item,index) in nianji" :key="item.id"/>
								</el-select>
							</div>
							<div class="flex-1 h-100 flex align-center mx-2">
								<el-input v-model="item.name" type="text" placeholder="请输入学生姓名" size="small" clearable class="flex-1"></el-input>
							</div>
							<div class="flex-1 h-100 flex align-center mr-2">
								<el-input v-model="item.jl_name" type="text" placeholder="请输入获奖名称" size="small" clearable class="flex-1"></el-input>
							</div>
						    <el-tooltip class="item" effect="dark" content="删除" placement="top">
								<el-button type="danger" size="mini" @click="delYpSpec(index)">
									<i class="el-icon-minus"></i>
								</el-button>
							</el-tooltip>
				    	</div>
						<div class="w-100 flex align-center mt-1">
							<el-button type="danger" size="mini" @click="addYpParams">
								<i class="el-icon-plus"></i>添加
							</el-button>
						</div>
				    </div>
				</el-form-item>
				<el-form-item label="辅导学生获国家金牌" v-if="form.project_4">
				    <div class="w-100 flex flex-column">
				    	<div class="flex-1 flex align-center mb-1" v-for="(item,index) in gjjp" :key="index">
							<div class="flex-1 h-100 flex align-center">
								<el-select v-model="item.nianji" placeholder="请选择年级" style="width: 100%;">
								  <el-option :value="item.name" :label="item.name" v-for="(item,index) in nianji" :key="item.id"/>
								</el-select>
							</div>
							<div class="flex-1 h-100 flex align-center mx-2">
								<el-input v-model="item.name" type="text" placeholder="请输入学生姓名" size="small" clearable class="flex-1"></el-input>
							</div>
							<div class="flex-1 h-100 flex align-center mr-2">
								<el-input v-model="item.jl_name" type="text" placeholder="请输入获奖名称" size="small" clearable class="flex-1"></el-input>
							</div>
						    <el-tooltip class="item" effect="dark" content="删除" placement="top">
								<el-button type="danger" size="mini" @click="delJpSpec(index)">
									<i class="el-icon-minus"></i>
								</el-button>
							</el-tooltip>
				    	</div>
						<div class="w-100 flex align-center mt-1">
							<el-button type="danger" size="mini" @click="addJpParams">
								<i class="el-icon-plus"></i>添加
							</el-button>
						</div>
				    </div>
				</el-form-item>
				<el-form-item label="上传材料" prop="files">
					<div class="flex flex-column flex-wrap">
						<div class="flex mr-1 mb-2">
							<el-upload
							  v-for="(item,index) in files" :key="index"
							  class="upload-border"
							  action="/manage/file/upload"
							  :show-file-list="false"
							  :on-success="uploadSuccess"
							  :before-upload="beforeUpload">
							  <div class="position-relative flex align-center flex-wrap w-100 overflow-hidden" style="height: 100px;" v-if="item.id">
								<img :src="item.path" style="width: 100px;height: 100px;" v-if="item.ext == 'jpg' || item.ext == 'jpeg' || item.ext == 'png'">
							  	<span style="width: 100px;word-wrap:break-word;word-break:break-all;overflow: hidden;" v-else>{{item.name || ''}}</span>
							  	<i class="el-icon-delete text-white position-absolute flex-all" style="width: 25px;height: 25px;top: 0;right: 0;background-color: rgba(0,0,0,0.4);cursor: pointer;" @click.stop="delFile(index)"></i>
							  </div>
							  <i v-else class="el-icon-plus uploader-icon"></i>
							</el-upload>
							<el-upload
							  v-if="files.length < 5"
							  class="upload-border"
							  action="/manage/file/upload"
							  :show-file-list="false"
							  :on-success="uploadSuccess"
							  :before-upload="beforeUpload">
							  <i class="el-icon-plus uploader-icon"></i>
							</el-upload>
						</div>
						<small class="d-block text-secondary" style="line-height: 20px;">上传照片及相关资料</small>
					</div>
				</el-form-item>
			</el-form>
			<div class="w-100 flex-all mt-2">
			  <el-button size="medium" @click="cancel">取 消</el-button>
			  <el-button type="primary" size="medium" @click="handleSubmit" style="margin-left: 30px;">确 定</el-button>
			</div>
		</div>
		</el-card>
	</div>
</template>

<script>
import common from "@/common/mixins/common.js"
import Breadcrumb from '@/components/Breadcrumb';
export default {
	inject:['app'],
	mixins:[common],
	components: {
		Breadcrumb,
	},
	data() {
		return {
			preUrl: 'user_jscj',
			autoRequest: false,
			queryForm: {
				page: 1,
				limit: 10,
				keyword: '',
			},
			form:{
				s_id:'',
				title:'',
				xueke:'',
				project_1:false,
				project_2:false,
				project_3:false,
				project_4:false,
				files:null,
			},
			rules:{
				s_id: [
				  { required: true, message: '请选择学期', trigger: ['blur','change'] }
				],
				title: [
				  { required: true, message: '请填写奖励名称', trigger: ['blur','change'] }
				],
				xueke: [
				  { required: true, message: '请选择学科', trigger: ['blur','change'] }
				],
				files: [
				  { required: true, message: '请上传文件', trigger: ['blur','change'] }
				],
			},
			files:[],
			xuekes:[
				{id:2,name:'数学'},
				{id:4,name:'物理'},
				{id:5,name:'化学'},
				{id:6,name:'生物'},
				{id:13,name:'信息技术'},
			],
			nianji:[],
			sjydj:[{nianji:'',name:'',jl_name:''}],
			fdjsd:[{nianji:'',name:'',jl_name:''}],
			gjyp:[{nianji:'',name:'',jl_name:''}],
			gjjp:[{nianji:'',name:'',jl_name:''}],
		}
	},
	mounted() {
		if(this.$route.query.info){
			 this.form = this.$route.query.info
			 this.files = this.$route.query.info.files
			 if(this.$route.query.info.sjydj.length > 0){
				 this.sjydj = this.$route.query.info.sjydj
			 }
			 if(this.$route.query.info.fdjsd.length > 0){
			 	this.fdjsd = this.$route.query.info.fdjsd
			 }
			 if(this.$route.query.info.gjyp.length > 0){
			 	this.gjyp = this.$route.query.info.gjyp
			 }
			 if(this.$route.query.info.gjjp.length > 0){
			 	this.gjjp = this.$route.query.info.gjjp
			 }
		}
		this.getsemester()
		this.getNianji()
	},
	methods:{
		reset() {
		  this.form = {
			s_id:'',
			title:'',
			xueke:'',
			project_1:false,
			project_2:false,
			project_3:false,
			project_4:false,
			files:null,
		  }
		  this.resetForm('form')
		},
		addSjParams(){
			this.sjydj.push({nianji:'',name:'',jl_name:''})
		},
		delSjSpec(index){
			this.sjydj.splice(index,1)
		},
		addSdParams(){
			this.fdjsd.push({nianji:'',name:'',jl_name:''})
		},
		delSdSpec(index){
			this.fdjsd.splice(index,1)
		},
		addYpParams(){
			this.gjyp.push({nianji:'',name:'',jl_name:''})
		},
		delYpSpec(index){
			this.gjyp.splice(index,1)
		},
		addJpParams(){
			this.gjjp.push({nianji:'',name:'',jl_name:''})
		},
		delJpSpec(index){
			this.gjjp.splice(index,1)
		},
		uploadSuccess(e){
		   if(e.status){
			 this.files.push(e.data)
		   }
		},
		beforeUpload(){},
		delFile(index){
			this.files.splice(index,1)
		},
		getNianji(){
			this.axios.get('/manage/nianji/all').then(res=>{
				if(res.status){
					let data = []
					res.data.forEach((v)=>{
						if(v.id < 10){
							data.push(v)
						}
					})
					this.nianji = data
				}
			})
		},
		handleSubmit() {
		  this.form.files = this.files
		  this.$refs.form.validate(valid => {
		    if (valid) {
			  this.form.sjydj = this.sjydj
			  this.form.fdjsd = this.fdjsd
			  this.form.gjyp = this.gjyp
			  this.form.gjjp = this.gjjp
		      if (this.form.id === undefined) {
		        this.axios.post('/manage/'+this.preUrl + '/add', this.form).then(res => {
		          if (res.status) {
		            this.$message.success('申请成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      } else {
		        this.axios.put('/manage/'+this.preUrl + '/edit/' + this.form.id, this.form).then(res => {
		          if (res.status) {
		            this.$message.success('修改成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      }
		    }
		  })
		},
		cancel(){
			this.$router.go(-1);
		},
	},
}
</script>

<style>
</style>